import * as wishlistbundle from "../libs/wishlist";
import {find, on, findAllIn, closest, findAll, trigger, findIn} from "@elements/dom-utils";
import {clearAll} from '@elements/alert-notification';
import {onFind} from "@elements/init-modules-in-scope";

let allLists = [];
if (window['_wishlist']) {
    allLists.push(wishlistbundle.createWishlist({
        ...window['_wishlist']
    }))
}

if (window['_compareLists']) {
    for(let i = 0; i < window['_compareLists'].length; i++){
        allLists.push(wishlistbundle.createWishlist({
            ...window['_compareLists'][i],
            translations: {
                add: 'comparelist.add',
                remove: 'comparelist.remove'
            }
        }, {
            item: '.js-comparelist-' + window['_compareLists'][i].wishlistName + '__btn',
            itemId: 'comparelist-id',
            wishlistId: window['_compareLists'][i].wishlistName,
            itemActiveClass: 'is-active'
        }));
    }
}

export function init() {
    if (allLists.length) {
        allLists.forEach((wishlist) => {
            wishlist.init();
        });
    }

    on('removed.wishlist', (evt) => {
        clearAll();

        let containerElement = find('.js-compare-list');

        if (containerElement) {
            setCellHeights(find('.js-compare-list'))

            trigger('submit', findIn('.js-ajax-form__form', closest('.js-ajax-form', containerElement)));
        }
    }, document);

    on('renderd.wishlist', (evt) => {
        if (evt.detail.activeIds && evt.detail.activeIds.length) {
            if (find('.js-compare-list')) {
                findAll('.js-compare-list').map((list) => setCellHeights(list));

                on('success.ajax-form', function () {
                    findAll('.js-compare-list').map((list) => setCellHeights(list));
                }, closest('.js-ajax-form', find('.js-compare-list')));
            }
        }
    }, document);

    //clear notifications
    on('added.wishlist', (evt) => {
        clearAll();
    }, document);

    on('product-prices.loaded', (evt) => {
        if (find('.js-compare-list')) {
            findAll('.js-compare-list').map((list) => setCellHeights(list));
        }
    }, document);

    on('product-item.changed', (evt) => {
        if (allLists.length) {
            allLists.forEach((wishlist) => {
                wishlist.renderWishlist(find('body'));
            })
        }
    }, document);

    onFind('.js-product-grid', function (productGrid) {
        on('product-grid.success', (evt) => {
            if (allLists.length) {
                allLists.forEach((wishlist) => {
                    wishlist.renderWishlist(find('body'));
                })
            }
        }, productGrid);
    });
}

export function initInScope(scope = find('body') ) {
    if (allLists.length) {
        allLists.forEach((wishlist) => {
            wishlist.initInScope(scope);

            // findAllIn('.js-compare-list', scope).forEach((container) => {
            //     let button = findIn('.js-compare-list__remove', container),
            //         id = container.getAttribute('data-comparelist-id');
            //
            //     const buttonClickHandler = (evt, button) => {
            //         evt.preventDefault();
            //         let _request = wishlist.remove(id);
            //
            //         if (_request) {
            //             _request.then(() => {
            //                 container.remove()
            //             })
            //         }
            //     };
            //
            //     button.addEventListener('click', (evt) => buttonClickHandler(evt, button));
            //
            //     return {
            //         cleanUp: () => {
            //             button.removeEventListener('click', (evt) => buttonClickHandler(evt, button));
            //         }
            //     };
            // });
        });
    }
}

export function setCellHeights(compareList) {
    let colHeights = {};
    findAllIn('.js-compare-list__col', compareList).map((colItem) => {
        let colName = colItem.getAttribute('data-col-name');
        if (colHeights[colName]) {
            colHeights[colName].push(colItem.getBoundingClientRect().height)
        } else {
            colHeights[colName] = [colItem.getBoundingClientRect().height];
        }

        let collapseParent = closest('.collapse', colItem);
        if (collapseParent) {
            collapseParent.addEventListener('shown.bs.collapse', function () {
                setCellHeights(closest('.js-compare-list', colItem));
            });
        }
    });

    let rowHeights = [];
    Object.keys(colHeights).forEach((item) => {
        rowHeights.push({name: item, height: Math.max(...colHeights[item])})
    });

    rowHeights.map((item) => {
        findAllIn('.js-compare-list__col' + '[data-col-name="'+ item.name +'"]', compareList).map((col) => {
            col.style.minHeight = item.height + 'px';
        });
    })
}

init();
initInScope(find('body'));